<template>
    <div class="Awarp">
        <dashCard class="photovoltaicBox5" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">光伏并网电站列表</template>
            <template slot="aside"></template>
            <template slot="operate"></template>
            <div class="list-content">
                <a-table ref="aTable" :columns="columns" :data-source="detailInfo.list" :pagination="false" :scroll="{y: tableHeight}" rowKey="id">
                    <a slot="name" slot-scope="text">{{ text }}</a>
                </a-table>
            </div>
            <div class="pagination">
                    <div class="left-pagination">
                        <font>显示</font>
                        <font>1-10</font>
                        <font>/</font>
                        <font>100</font>
                    </div>
                    <div class="right-pagination">
                        <div class="pagination-sizes">
                            <font>{{commonLangHandler('chuneng14_page','分页', getZEdata)}}</font>
                            <span>1/10</span>
                        </div>
                        <div class="pagination-btngroup">
                            <i class="lowcode icon-left"></i>
                            <i class="lowcode icon-right"></i>
                        </div>
                    </div>
                </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <photovoltaicBox5  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'photovoltaicBox5',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        this.getElHeight()
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                searchObj: {},
                tableHeight: 400,
                columns: [
                { title: '电站名称', dataIndex: 'word1', key: 'word1',defaultSortOrder: 'descend',scopedSlots: { customRender: 'name' }, },
                { title: '组串总装机容量（kWp）', dataIndex: 'word2', key: 'word2',defaultSortOrder: 'descend', },
                { title: '当前光伏发电功率（kW）', dataIndex: 'word3', key: 'word3',defaultSortOrder: 'descend', },
                { title: '当日等效利用小时（h）', dataIndex: 'word4', key: 'word4',defaultSortOrder: 'descend', },
                { title: '当日发电量（kWh）', dataIndex: 'word5', key: 'word5',defaultSortOrder: 'descend', },
                { title: '总发电量（kWh）', dataIndex: 'word6', key: 'word6',defaultSortOrder: 'descend', },
                { title: '当日系统效率(%)', dataIndex: 'word7', key: 'word7',defaultSortOrder: 'descend', },
                { title: '当日总辐照量（kWh_m2）', dataIndex: 'word8', key: 'word8',defaultSortOrder: 'descend', },
                { title: '并网时间', dataIndex: 'word9', key: 'word9',defaultSortOrder: 'descend', },
                { title: '当日设备利用率（%）', dataIndex: 'word10', key: 'word10',defaultSortOrder: 'descend', },
                ],
                detailInfo: {
                    list: []
                },
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },


        handleChange(value) {
            console.log("handleChange",value,this.searchObj)
        },
        // 获取数据
        getData() {
            this.isLoading = true
            this.detailInfo = {}
            var list = [
                { id: 1,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 2,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 3,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 4,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 5,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 6,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 7,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 8,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 9,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },
                { id: 10,word1: "行政楼地下室配电间",word2: "1200",word3: "720",word4: "2",word5: "1420",word6: "15630",word7: "93.33%",word8: "1000 ",word9: "2021-12-08 ",word10: "92.78", },


            ]
            this.detailInfo.list = list
            this.isLoading = false
        },
        getElHeight() {
            if(!!this.$el) {
                // this.tableHeight = this.$el.clientHeight - 180
                this.tableHeight = this.$el.clientHeight - 180
            }
        },            


            
        },
        mounted() {
            this.getOperateWidth()
            this.getElHeight()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.photovoltaicBox5 {
    .list-content {
        position: relative;
        height: inherit;
    }
    /deep/.ant-table-thead {

        tr{
            th{
                background: #fafafa;
                padding: 9px 16px;
            }
        }

    }
/deep/.ant-table-body {
    .ant-table-column-sort {
        background: #fff;
    }
    .ant-table-thead  {
        tr {
            th {
                background: transparent;

            }
        }
    }
    .ant-table-tbody {
        td {
            padding: 8px 16px;

        }
    }
    }
    .icon-weishoucang {
        &:hover {
            color: #3366FF;
            cursor: pointer;
        }
    }
    .pagination {
        margin-top: 16px;
        height: 33px;
        line-height: 33px;
        display: flex;
        justify-content: space-between;
        .left-pagination {
            font-size: 14px;
            font-weight: 400;
            color: #1f1f1f;
            line-height: 32px;
            font {
                display: inline-block;
                margin-right: 2px;
                &:nth-child(2) {
                    border-bottom: 1px solid #bababa;
                }
            }
        }
        .right-pagination {
            display: flex;
            .pagination-sizes {
                font-size: 14px;
                font-weight: 400;
                color: #1f1f1f;
                span {
                    display: inline-block;
                    line-height: 33px;
                    border-bottom: 1px solid #bababa;
                }
            }
            .pagination-btngroup {
                margin-left: 41px;
                i {
                    color: rgba(31,31,31,.16);
                    &:nth-child(2) {
                        margin-left: 16px;
                        color: #1F1F1F;
                    }
                }
            }


        }

    }
}
</style>